//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
//import axios from "axios";
import WeekScheduler from "@/components/WeekScheduler";
// import PrinterPrice from "@/components/management/printers/PrinterPrice";
import PrinterCustomSchedule from "@/components/management/printers/PrinterCustomSchedule.vue";
import SetServicesList from './SetServicesList.vue';
import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";
import SetterMetro from '../../management/metro/setterMetro.vue';

const copyArrObj = new CopyArrayAndObject();

export default {
  name: "CreateObject",
  components: { WeekScheduler, PrinterCustomSchedule, SetServicesList, SetterMetro },
  data(){
    return {
      data: {
        legal_person_id: 0,
        name: '',
        address: '',
        latitude: '',
        longtitude: '',
        city_id: '',
        metro_station_id: '0',
        timezone: '3',
        directions: '',
        notice: '',
        currency_id: 1,
        is_on: 1,
        is_production: 0,
        franchiseRelation: {franch_rel_id: 0},
        workSchedule: [],
        workScheduleCustom: [],
        services: []
      },
      submitted: false,
    }
  },
  props: {
    currencyList: Array,
    metroStations: Array,
    timezones: Object,
    //franchises: Array,
    legalRelations: Array,
    cities: Array,
    servicesList: Array,
    submit: Number
  },
  watch: {
    submit: function(val){
      if(val > 0){
        this.submitCreate();
      }
    }
  },
  computed: {
    getServicesListCopy(){
      return copyArrObj.copy(this.servicesList);
    },
  },
  validations: {
    data: {
      name: { required, minLength: minLength(1), maxLength: maxLength(255) },
      address: { required, minLength: minLength(1), maxLength: maxLength(255) },
      longtitude: { required },
      latitude: { required },
      city_id: { required, minValue: minValue(1) },
      directions: { required, minLength: minLength(1), maxLength: maxLength(5000) },
      notice: { maxLength: maxLength(255) },
      franchiseRelation: {
        franch_rel_id: { required, minValue: minValue(1) },
      }
    }
  },
  methods: {
    setServicesList(val){
      this.data.services = val;
    },
    setWorkSchedule(val){
      this.data.workSchedule = val;
    },
    setWorkScheduleCustom(val){
      this.data.workScheduleCustom = val;
    },
    submitCreate(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.data.$touch();
      if(!this.$v.data.$invalid){
        this.$emit('submitCreateObject', this.data);
      }
    },
  },
  created() {

  }

};
