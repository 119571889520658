//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";
//import Formatter from "@/components/libraries/Formatter";

//const formatter = new Formatter();

const copyArrObj = new CopyArrayAndObject();

export default {
  name: "SetServicesList",
  data() {
    return {
      list: [],
      uid: this._uid
    };
  },
  props: {
    servicesList: Array,
    default: Array
  },
  computed: {},
  watch: {
    servicesList: function(val){
      this.setList(val);
    },
    default: function(val){
      this.setDefault(val);
    },
    list:{
      handler(val){
        this.emitByVal(val);
      },
      deep: true
    }
  },
  methods: {
    getUid(){
      return this._uid;
    },
    getfieldName(serviceId, fieldCode){
      if(fieldCode == 'percent_to_partner'){
        return this.$t('views.points.percent_to_partner');
      }
      for(let key in this.servicesList){
        if(+this.servicesList[key].id === +serviceId){
          for(let fieldKey in this.servicesList[key].price_fields.fields){
            if(fieldKey == fieldCode){
              return this.servicesList[key].price_fields.fields[fieldKey];
            }
          }
        }
      }
    },
    setDefault(val){
      this.setList(this.servicesList);

      for(let keyList in this.list){
        for(let keyDefault in val){
          if(+val[keyDefault].id === +this.list[keyList].id){
            this.$set(this.list[keyList], 'percent_to_partner', val[keyDefault].percent_to_partner);
            this.$set(this.list[keyList], 'checked', true);

            for(let modelList in this.list[keyList].models){
              for(let modelDefault in val[keyDefault].models){
                if(modelDefault == modelList){
                  this.$set(this.list[keyList].models, modelList, val[keyDefault].models[modelDefault]);
                }
              }
            }
          }
        }
      }
    },
    setList(data){
      let result = [];
      for(let key in data){
        result[result.length] = copyArrObj.copy(data[key]);
        this.$set(result[result.length -1], 'checked', false);
        let models = {};
        result[result.length -1].percent_to_partner = '';
        for(let fieldKey in result[result.length -1].price_fields.fields){
          models[fieldKey] = '';
        }
        this.$set(result[result.length -1], 'models', models);
      }
      this.list = result;
    },
    checkServiceAndEmit(id){
      for(let key in this.list){
        if(+this.list[key].id === +id){
          this.$set(this.list[key], 'checked', !this.list[key].checked);
        }
      }
      this.emitData();
    },
    emitData(){
      let result = [];
      for(let key in this.list){
        if(this.list[key].checked){
          result.push(this.list[key]);
        }
      }
      this.$emit('emitComponent', result);
    },
    emitByVal(val){
      let result = [];
      for(let key in val){
        if(val[key].checked){
          result.push(val[key]);
        }
      }
      this.$emit('emitComponent', result);
    }
  },
  created() {},
  destroyed(){
    
  }
};
